import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import MEDIA from '../styles/media';

export default class ThankYouModule extends PureComponent {
  static propTypes = {
    title: PropTypes.string,
    subTitle: PropTypes.string,
    buttonText: PropTypes.string,
    buttonLink: PropTypes.string,
    buttonClick: PropTypes.func
  };
  render() {
    const { title, subTitle, buttonText, buttonLink, buttonClick } = this.props;
    return (
      <StyledThankYou>
        <div className="title">{title}</div>
        <div className="subtitle">{subTitle}</div>
        <a className="button" href={buttonLink} onClick={buttonClick}>
          {buttonText}
        </a>
      </StyledThankYou>
    );
  }
}

const StyledThankYou = styled.section`
  margin: 4rem 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .title {
    margin: 0 0 2rem;
    font-family: ${props => props.theme.fonts.secondary};
    font-size: 2.25rem;
    font-weight: 300;
    line-height: 1.25;
    color: #6d6e71;
    ${MEDIA.TABLET`
          font-size: 2rem;
    `};
  }
  .subtitle {
    margin: 0 0 2rem;
    max-width: 772px;
  }
  .button {
    margin: 0 0 2rem;
    padding: 1rem;
    color: #fff;
    background-color: ${props => props.theme.colors.secondary};
    text-decoration: none;
  }
`;
