import React, { PureComponent } from 'react';
import { graphql } from 'gatsby';
import NavModule from '../components/nav-module';
import HeroModule from '../components/hero-module';
import FloorPlanModule from '../components/floor-plan-module';
import GalleryModule from '../components/gallery-module';
import FeaturesModule from '../components/features-module';
import BuildingFacilityModule from '../components/building-facility-module';
import ClaytonBuiltModule from '../components/clayton-built-module';
import FooterModule from '../components/footer-module';
import Layout from '../components/layout';
import LegalFooter from '../components/legal-footer';
import dataLayer from '../util/data-layer';
import ThankYouModule from '../components/thank-you-module';

export default class ThankYouPage extends PureComponent {
  handlePhoneClick = () => {
    dataLayer.addToDataLayer({
      event: 'link-phone-call-nav'
    });
  };

  handleFloorPlanClick = (floorplan) => {
    dataLayer.addToDataLayer({
      event: 'button-expand-floor-plan',
      floorPlanName: floorplan
    });
  };

  handleGalleryClick = () => {
    dataLayer.addToDataLayer({
      event: 'button-gallery-interaction'
    });
  };

  handleFeaturesClick = () => {
    dataLayer.addToDataLayer({
      event: 'button-features-interaction'
    });
  };

  handleVideoClick = () => {
    dataLayer.addToDataLayer({
      event: 'video-play-button'
    });
  };

  handleThankYouButtonClick = () => {
    dataLayer.addToDataLayer({
      event: 'button-thank-you-cta'
    });
  };

  render() {
    const {
            nav,
            hero,
            floorplan,
            gallery,
            features,
            buildingFacility,
            claytonBuilt,
          } = this.props.data.homeJson;
    return (
      <Layout>
        <NavModule logo={nav.image} phone={nav.phone} onPhoneClick={this.handlePhoneClick}/>
        <HeroModule title={hero.title} subTitle={hero.subTitle} legal={hero.legal} image={hero.image} />
        <ThankYouModule
          title={'Thanks!'}
          subTitle={'Our team of home specialists will reach out to you shortly about our NXT homes.'}
          buttonText={'Learn more about NXT'}
          buttonLink={'https://www.nxtathens.com'}
          buttonClick={this.handleThankYouButtonClick}
        />
        <FloorPlanModule title={floorplan.title} legal={floorplan.legal} items={floorplan.items} floorPlanClick={this.handleFloorPlanClick} />
        <GalleryModule gallery={gallery} galleryClick={this.handleGalleryClick}/>
        <FeaturesModule
          title={features.title}
          subTitle={features.subTitle}
          items={features.items}
          featureClick={this.handleFeaturesClick}
        />
        <BuildingFacilityModule
          title={buildingFacility.title}
          body={buildingFacility.body}
          image={buildingFacility.image}
        />
        <ClaytonBuiltModule
          title={claytonBuilt.title}
          subTitle={claytonBuilt.subTitle}
          body={claytonBuilt.body}
          videoLeadIn={claytonBuilt.videoLeadIn}
          videoTitle={claytonBuilt.videoTitle}
          videoWistiaId={claytonBuilt.videoWistiaId}
          videoClick={this.handleVideoClick}
        />
        <LegalFooter/>
        <FooterModule />
      </Layout>
    );
  }
}

export const query = graphql`
  query ThankYouPageQuery {
    homeJson {
      nav {
        image {
          title
          alt
        }
        phone
      }
      hero {
        title
        subTitle
        legal
        image {
          title
          alt
          src {
            childImageSharp {
              fluid {
                base64
                aspectRatio
                src
                srcSet
                sizes
                originalImg
                originalName
              }
            }
          }
        }
      }
      ctaForm {
        title
        subTitle
        formLegal
        buttonText
      }
      floorplan {
        title
        legal
        items {
          name
          size
          beds
          baths
          image {
            title
            alt
            src {
              childImageSharp {
                fluid {
                  base64
                  aspectRatio
                  src
                  srcSet
                  sizes
                  originalImg
                  originalName
                }
              }
            }
          }
        }
      }
      gallery {
        category
        title
        body
        image {
          title
          alt
          src {
            childImageSharp {
              fluid {
                base64
                aspectRatio
                src
                srcSet
                sizes
                originalImg
                originalName
              }
            }
          }
        }
      }
      features {
        title
        subTitle
        items {
          name
          image {
            title
            alt
            src {
              childImageSharp {
                fluid {
                  base64
                  aspectRatio
                  src
                  srcSet
                  sizes
                  originalImg
                  originalName
                }
              }
            }
          }
        }
      }
      buildingFacility {
        title
        body
        image {
          title
          alt
          src {
            childImageSharp {
              fluid {
                base64
                aspectRatio
                src
                srcSet
                sizes
                originalImg
                originalName
              }
            }
          }
        }
      }
      claytonBuilt {
        title
        subTitle
        body
        videoLeadIn
        videoTitle
        videoWistiaId
      }
      ctaModule {
        title
        buttonText
      }
    }
  }
`;
